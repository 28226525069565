exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-build-js": () => import("./../../../src/pages/admin/build.js" /* webpackChunkName: "component---src-pages-admin-build-js" */),
  "component---src-pages-admin-callback-index-js": () => import("./../../../src/pages/admin/callback/index.js" /* webpackChunkName: "component---src-pages-admin-callback-index-js" */),
  "component---src-pages-admin-contacts-js": () => import("./../../../src/pages/admin/contacts.js" /* webpackChunkName: "component---src-pages-admin-contacts-js" */),
  "component---src-pages-admin-email-templates-edit-js": () => import("./../../../src/pages/admin/emailTemplates/edit.js" /* webpackChunkName: "component---src-pages-admin-email-templates-edit-js" */),
  "component---src-pages-admin-email-templates-index-js": () => import("./../../../src/pages/admin/emailTemplates/index.js" /* webpackChunkName: "component---src-pages-admin-email-templates-index-js" */),
  "component---src-pages-admin-forms-create-js": () => import("./../../../src/pages/admin/forms/create.js" /* webpackChunkName: "component---src-pages-admin-forms-create-js" */),
  "component---src-pages-admin-forms-index-js": () => import("./../../../src/pages/admin/forms/index.js" /* webpackChunkName: "component---src-pages-admin-forms-index-js" */),
  "component---src-pages-admin-images-js": () => import("./../../../src/pages/admin/images.js" /* webpackChunkName: "component---src-pages-admin-images-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-layout-js": () => import("./../../../src/pages/admin/Layout.js" /* webpackChunkName: "component---src-pages-admin-layout-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admin-mail-index-js": () => import("./../../../src/pages/admin/mail/index.js" /* webpackChunkName: "component---src-pages-admin-mail-index-js" */),
  "component---src-pages-admin-menus-js": () => import("./../../../src/pages/admin/menus.js" /* webpackChunkName: "component---src-pages-admin-menus-js" */),
  "component---src-pages-admin-pages-js": () => import("./../../../src/pages/admin/pages.js" /* webpackChunkName: "component---src-pages-admin-pages-js" */),
  "component---src-pages-admin-posts-choose-post-js": () => import("./../../../src/pages/admin/posts/choosePost.js" /* webpackChunkName: "component---src-pages-admin-posts-choose-post-js" */),
  "component---src-pages-admin-posts-edit-js": () => import("./../../../src/pages/admin/posts/edit.js" /* webpackChunkName: "component---src-pages-admin-posts-edit-js" */),
  "component---src-pages-admin-posts-index-js": () => import("./../../../src/pages/admin/posts/index.js" /* webpackChunkName: "component---src-pages-admin-posts-index-js" */),
  "component---src-pages-admin-posts-select-js": () => import("./../../../src/pages/admin/posts/select.js" /* webpackChunkName: "component---src-pages-admin-posts-select-js" */),
  "component---src-pages-admin-sections-js": () => import("./../../../src/pages/admin/sections.js" /* webpackChunkName: "component---src-pages-admin-sections-js" */),
  "component---src-pages-admin-settings-js": () => import("./../../../src/pages/admin/settings.js" /* webpackChunkName: "component---src-pages-admin-settings-js" */),
  "component---src-pages-admin-tags-js": () => import("./../../../src/pages/admin/tags.js" /* webpackChunkName: "component---src-pages-admin-tags-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-post-index-js": () => import("./../../../src/pages/post/index.js" /* webpackChunkName: "component---src-pages-post-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-technology-index-js": () => import("./../../../src/pages/technology/index.js" /* webpackChunkName: "component---src-pages-technology-index-js" */)
}

